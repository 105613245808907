<template>
  <div v-if="model && options" class="wrapper">
    <div class="section-wrap section-wrap--setttings">
      <div class="container settings__container">
        <div class="maincontent">
          <template
            v-if="currentUser && currentUser.blogger_profile && currentUser.blogger_profile.filled"
          >
            <div
              class="mb-4 hovering__pad p-4"
              style="border: 1px solid red"
              v-if="
                moderationHistoryData &&
                !(currentUser.blogger_profile.moderation_status === 2) &&
                enums.bloggers.moderation_statuses.groups.unsafe.includes(
                  moderationHistoryData.moderation_status
                )
              "
            >
              <div
                class="mb-3"
                v-for="reason in moderationHistoryData.groups
                  .map((val) => val.reasons_interpolated_json)
                  .flat()"
                :key="reason.description"
              >
                <h5 class="mb-2">{{ reason.description }}</h5>
                <p style="white-space: pre-line">
                  {{ reason.instructions }}
                </p>
              </div>
              <p>
                После выполнения всех вышеперечисленных действий,
                <b-button style="display: contents" @click="submit" variant="link"
                  >сохраните изменения</b-button
                >
                и ожидайте обработки вашей карточки модератором, обычно это занимает 1 - 2 дня.
              </p>
            </div>
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="statSett"
              ref="statSett"
              :header="'Статистика вашего аккаунта'"
              :subheader="'Для карточки блогера'"
            >
              <template v-slot:content>
                <main
                  class="d-flex flex-row justify-content-between flex-wrap px-3 px-llg-4 px-xlg-5"
                >
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Охваты сторис
                      <a
                        @click="showFill('stories-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <coverage-field :validations="$v" v-model="model"></coverage-field>
                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Охваты постов
                      <a
                        @click="showFill('posts-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <coverage-posts-field :validations="$v" v-model="model"></coverage-posts-field>
                    <div class="settings__info--text--down settings__info--text--down--error"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Процент женщин
                      <a
                        @click="showFill('women-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <women-percent-field :validations="$v" v-model="model"></women-percent-field>

                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающая страна
                      <a
                        @click="showFill('country-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <main-country-field
                      :validations="$v.model.blogger_profile.main_country"
                      v-model="model.blogger_profile.main_country"
                    ></main-country-field>
                    <div class="settings__info--text--down settings__info--text--down--error"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающий возраст
                      <a
                        @click="showFill('audience-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <main-audience-field
                      :validations="$v.model.blogger_profile.main_audience"
                      v-model="model.blogger_profile.main_audience"
                    ></main-audience-field>
                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающий город
                      <a
                        @click="showFill('city-example')"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <main-city-field
                      :validations="$v.model.blogger_profile.main_city"
                      v-model="model.blogger_profile.main_city"
                    ></main-city-field>
                    <div class="settings__info--text--down"></div>
                  </div>
                </main>
                <div
                  class="
                    d-flex
                    justify-content-between
                    flex-wrap
                    settings__info--footer settings__info--footer--common
                  "
                >
                  <b-button
                    @click="submit"
                    :disabled="!bloggerProfileChanged"
                    :variant="bloggerProfileChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div></template
              >
            </SettingsBlock>
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="themeSett"
              header="Темы аккаунта и тезисы"
              subheader="Для карточки блогера и ВП на завтра"
            >
              <template v-slot:content>
                <main class="settings__themes">
                  <div class="settings__themes--wrap">
                    <b-form-group>
                      <h3 class="settings__title--small">Тезисы</h3>
                      <textarea
                        v-model="model.blogger_profile.theses"
                        name="themes-textarea"
                        class="settings__themes--textarea"
                      ></textarea>
                    </b-form-group>
                  </div>
                  <div class="settings__themes--wrap">
                    <div class="setting__themes--block">
                      <h3 class="settings__title--small">Укажите 1-3 темы вашего аккаунта</h3>
                      <Multiselect
                        v-model="model.blogger_profile.tags"
                        placeholder="Выберите из списка"
                        :multiple="true"
                        :max="3"
                        :show-labels="false"
                        :options="tags"
                        track-by="name"
                        label="name"
                      />
                    </div>
                    <div class="setting__themes--block">
                      <h3 class="settings__title--small">Какие темы не рекламируете</h3>
                      <textarea
                        v-model="model.blogger_profile.excluded_tags"
                        name="themes-textarea"
                        style="font-size: 14px"
                        placeholder="Например: Cетевой бизнес, астрология, гадания, казино."
                        class="settings__themes--textarea settings__themes--textarea--2"
                      ></textarea>
                    </div>
                  </div>
                </main>
                <div class="settings__info--footer settings__info--footer--common">
                  <b-button
                    @click="submit"
                    :disabled="!commonBloggerDataChanged"
                    :variant="commonBloggerDataChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div>
              </template>
            </SettingsBlock>
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="kindSett"
              contentClass="kinds-sett-content"
              header="Форматы рекламы"
              subheader="Для карточки блогера"
            >
              <template v-slot:content>
                <nested-list-form-container
                  v-if="model && model.blogger_profile"
                  v-model="model.blogger_profile.pr_types"
                  :formComponent="PrTypeForm"
                  :templateObject="prTypeModel"
                >
                  <template v-slot:upper="{ increment }">
                    <b-button
                      @click="increment"
                      class="format_pr-btn-sett"
                      variant="outline-default"
                      >Добавить новый формат рекламы</b-button
                    ></template
                  ></nested-list-form-container
                >
                <div
                  class="
                    settings__info--footer settings__info--footer--common
                    pr-types-sett__footer
                  "
                >
                  <b-button @click="submit" :variant="'primary'">Сохранить изменения</b-button>
                </div>
              </template>
            </SettingsBlock>
          </template>
          <template v-else-if="currentUser.blogger_profile">
            <SettingsBlock
              id="statSett"
              :foldable="false"
              header="Настройки карточки"
              :subheader="'Аккаунт easyprbot'"
            >
              <template #content>
                <div class="p-4 d-flex flex-column align-items-center justify-content-between">
                  <p class="text-center">Вы недозаполнили карточку блогера! Настройки недоступны</p>
                  <b-button class="mt-3" :to="{ name: 'fill_statistics' }" variant="yellow"
                    >Заполнить карточку</b-button
                  >
                </div>
              </template>
            </SettingsBlock>
          </template>
          <SettingsBlock
            id="defSett"
            :foldable="false"
            header="Общие настройки"
            :subheader="'Аккаунт easyprbot'"
          >
            <template v-slot:content>
              <main class="settings__common pb-2">
                <div
                  class="
                    settings__info--footer settings__footer--mobile settings__info--footer--common
                  "
                >
                  <b-button
                    @click="submit"
                    :disabled="!commonDataChanged"
                    :variant="commonDataChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div>
                <div class="settings__common--wrap">
                  <div class="settings__common--block">
                    <h4
                      class="settings__title--small"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 94%;
                        align-items: center;
                      "
                    >
                      <span>Ваш телефон</span>
                      <span
                        @click="toggleChangePhoneModal({ open: true, context: {} })"
                        class="settings__title--blue"
                        >Изменить</span
                      >
                    </h4>
                    <PhoneMaskInput
                      autoDetectCountry
                      disabled
                      id="tel"
                      inputClass="settings__input"
                      :value="
                        currentUser.profile.phone
                          ? `+${currentUser.profile.phone}`
                          : '+79999999999 (не указан)'
                      "
                      placeholder="В формате, +7 (999) 999-99-99"
                    />
                    <div
                      :title="currentUser.profile.phone ? '' : 'Сначала укажите номер телефона'"
                      :style="{ color: currentUser.profile.phone ? '' : 'lightgray' }"
                      @click="
                        () =>
                          toggleChangePasswordModal({
                            open: true,
                            context: {
                              email: currentUser.profile.email ? currentUser.profile.email : null,
                              phone: currentUser.profile.phone ? currentUser.profile.phone : null,
                            },
                          })
                      "
                      class="settings__title--blue settings__title--blue--psw"
                    >
                      Изменить пароль
                    </div>
                  </div>
                  <div style="position: relative" class="settings__common--block">
                    <!--div
                      title="Скоро!"
                      style="
                        opacity: 0.6;
                        background: white;
                        z-index: 5;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                      "
                    ></!--div-->
                    <h4
                      class="settings__title--small"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 94%;
                        align-items: center;
                      "
                    >
                      <div class="settings__title--small--text">Ваш e-mail</div>
                      <!--div class="freeauto__date--icon">
                        <div class="freeauto__date--quest">?</div>
                      </div-->
                      <b-button
                        variant="link"
                        :disabled="
                          !validateEmail(model.profile.email) ||
                          currentUser.profile.email === model.profile.email
                        "
                        style="margin: auto 0 auto auto"
                        @click="changeEmail"
                        class="settings__title--blue"
                        >Сохранить</b-button
                      >
                    </h4>
                    <input
                      type="email"
                      spellcheck="false"
                      name="mail"
                      class="settings__input"
                      v-model="model.profile.email"
                      id="mail"
                    />
                    <div
                      v-if="currentUser.profile.email && !currentUser.profile.email_confirmed"
                      @click="toggleEmailActivationModal({ open: true })"
                      class="settings__title--blue settings__title--blue--psw"
                    >
                      <span style="color: #ff0000; margin-right: 10px">E-mail не подтвержден.</span
                      >Подтвердить
                    </div>
                  </div>
                </div>
                <template v-if="currentUser.blogger_profile && currentUser.blogger_profile.filled">
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">Прикрепленный аккаунт</h4>
                      <div class="settings__title--blue settings__title--blue--psw settings--nick">
                        {{ currentUser.blogger_profile.instaname }}
                      </div>
                      <b-button
                        variant="outline-default"
                        style="margin-top: 9px"
                        :to="{ name: 'change-instaname' }"
                        >Обновить ник</b-button
                      >
                    </div>
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">PR-аккаунт</h4>
                      <a v-if="model.blogger_profile.pr_account">
                        <div
                          class="settings__title--blue settings__title--blue--psw settings--nick"
                        >
                          {{ model.blogger_profile.pr_account }}
                        </div>
                      </a>
                      <p v-else style="color: #ff0000" class="">PR-аккаунт не указан!</p>
                      <b-button
                        @click="
                          () =>
                            toggleInstagramVerificationModal({
                              open: true,
                              context: { prAccount: true },
                            })
                        "
                        variant="outline-default"
                        style="margin-top: 9px; font-size: 14px"
                      >
                        {{ model.blogger_profile.pr_account ? 'Изменить' : 'Указать' }} pr-аккаунт
                      </b-button>
                    </div>
                  </div>
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">
                        Город проживания <span style="opacity: 0.5">(необязательно)</span>
                      </h4>
                      <b-input
                        type="text"
                        name="town"
                        class="settings__input ph-36"
                        v-model="model.blogger_profile.current_city"
                        id="town"
                      />
                      <div class="settings__common--opac">
                        Коммерческие аккаунты почти всегда интересуются городом блогеров
                      </div>
                    </div>
                    <div class="settings__common--block">
                      <h4 class="settings__title--small" style="display: flex">
                        <div class="settings__title--small--text">
                          Амбассадорство <span style="opacity: 0.5">(необязательно)</span>
                        </div>
                        <!--div class="freeauto__date--icon">
                        <div class="freeauto__date--quest">?</div>
                      </div-->
                      </h4>
                      <input
                        class="setting__common--check"
                        type="checkbox"
                        v-model="model.blogger_profile.ambassador"
                        id="ambassadorship"
                        name="ambassadorship"
                      />
                      <label class="setting__common--label" for="ambassadorship"
                        >Хочу стать амбассадором</label
                      >
                    </div>
                  </div>
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">
                        Юридический статус <span style="opacity: 0.5">(необязательно)</span>
                      </h4>

                      <legal-status-radio-group
                        :options="options.legal_status.choices"
                        v-model="model.blogger_profile"
                      ></legal-status-radio-group>
                    </div>
                    <div style="position: relative !important" class="settings__common--block">
                      <div
                        title="Скоро!"
                        style="
                          opacity: 0.6;
                          background: white;
                          z-index: 5;
                          position: absolute;
                          width: 100%;
                          height: 100%;
                        "
                      ></div>
                      <h4 class="settings__title--small">
                        Какая аудитория вас интересует
                        <div style="opacity: 0.5">(ВП на завтра)</div>
                      </h4>
                      <div class="settings__common--checkboxes">
                        <div class="settings__common--checkboxes--block">
                          <input
                            class="setting__common--check"
                            type="checkbox"
                            id="region"
                            name="region"
                          />
                          <label class="setting__common--label" for="region">Все регионы</label>
                          <input
                            :value="countries.filter((val) => val.short_key === 'RU')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Russia"
                            name="Russia"
                          />
                          <label class="setting__common--label" for="Russia">Россия</label>
                        </div>
                        <div class="settings__common--checkboxes--block">
                          <input
                            :value="countries.filter((val) => val.short_key === 'UA')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Ukraine"
                            name="Ukraine"
                          />
                          <label class="setting__common--label" for="Ukraine">Украина</label>
                          <input
                            :value="countries.filter((val) => val.short_key === 'KZ')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Kazah"
                            name="Kazah"
                          />
                          <label class="setting__common--label" for="Kazah">Казахстан</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%" class="settings__common--wrap">
                    <div style="margin-left: 0px !important" class="settings__common--block">
                      <h4 class="settings__title--small">
                        Ссылка на вашу карточку в базе блогеров
                      </h4>
                      <div class="d-flex flex-row w-sm-100">
                        <b-input
                          type="text"
                          disabled
                          :value="bloggerLink"
                          class="w-lg-25 ph-36"
                          name="link"
                          id="link"
                        />
                        <b-button
                          variant="outline-default"
                          v-clipboard:copy="bloggerLink"
                          @click="
                            () => {
                              bloggerLinkCopied = true;
                              window.setTimeout(() => {
                                bloggerLinkCopied = false;
                              }, 1000);
                            }
                          "
                          class="ml-2"
                        >
                          {{ bloggerLinkCopied ? 'Скопировано' : 'Скопировать' }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
              </main>
              <div class="settings__info--footer settings__info--footer--common">
                <b-button
                  v-if="currentUser.blogger_profile"
                  @click="submit"
                  :disabled="!commonDataChanged"
                  :variant="commonDataChanged ? 'primary' : 'outline-default'"
                  >Сохранить изменения</b-button
                >
              </div>
            </template>
          </SettingsBlock>
          <!--SettingsBlock id="paySett">
            <template v-slot:content>
              <main class="payment payment--descop">
                <ul class="payment__list">
                  <li
                    class="payment__item"
                    style="
                      padding-bottom: 30px;
                      padding-top: 28px;
                      border-bottom: 1px solid #d5dfe4;
                    "
                  >
                    <h3 class="payment__title">
                      <span class="payment__title--text">Подписка ВП на завтра неактивна</span>
                      <span class="payment__title--icon payment__title--icon--close">
                        <span class="payment__title--wrap--close">×</span></span
                      >
                    </h3>
                    <div class="payment__title payment__title--small">Доступ к трекеру на:</div>
                    <div class="payment__radios">
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">1 месяц</div>
                            <div class="payment-radio__text">
                              <span>990 ₽</span>
                              <span class="payment-radio__text--opac">/ 33 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label
                        class="settings__radio--button payment__radio"
                        style="margin-bottom: 0"
                      >
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">2 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 560 ₽ </span>
                              <span class="payment-radio__text--opac">/ 26 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">3 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 980 ₽</span>
                              <span class="payment-radio__text--opac">/ 22 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <a  style="text-decoration: none"
                      ><b-button variant="primary" class="pay-btn">Оплатить</b-button></a
                    >
                  </li>
                  <li class="payment__item" style="padding-top: 28px">
                    <h3 class="payment__title">
                      <span class="payment__title--text">Доступ к трекеру неактивен</span>
                      <span class="payment__title--icon payment__title--icon--close">
                        <span class="payment__title--wrap--close">×</span></span
                      >
                    </h3>
                    <div class="settings__common--opac payment__opac">
                      Трекер позволяет: отслеживать результаты рекламы блогеров на основании
                      информации из отзывов; добавлять блогеров в ваш список и отслеживать прогресс
                      своей рекламы; оставлять отзывы о рекламе; отслеживать историю своих отзывов;
                      поставить цель и рассчитать показатели для рекламы у блогеров.
                    </div>
                    <div class="payment__title payment__title--small">Премиум аккаунт на:</div>
                    <div class="payment__radios">
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">1 месяц</div>
                            <div class="payment-radio__text">
                              <span>990 ₽</span>
                              <span class="payment-radio__text--opac">/ 33 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label
                        class="settings__radio--button payment__radio"
                        style="margin-bottom: 0"
                      >
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">2 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 560 ₽ </span>
                              <span class="payment-radio__text--opac">/ 26 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text radio__text--mobile">3 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 980 ₽</span>
                              <span class="payment-radio__text--opac">/ 22 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <a href="#" style="text-decoration: none"
                      ><b-button variant="primary" class="pay-btn">Оплатить</b-button></a
                    >
                  </li>
                </ul>
              </main>
            </template>
          </SettingsBlock-->
          <!-- TODO - ВЕРНУТЬ УВЕДОМЛЕНИЯ ПРИ ВЫГРУЗКЕ ВП -->
          <SettingsBlock
            id="notifSett"
            :foldable="true"
            header="Уведомления"
            :subheader="'Аккаунт easyprbot'"
          >
            <template v-slot:content>
              <main class="notification">
                <div class="notification__block">
                  <div class="notification__title">На почту</div>
                  <div class="notification__title--small">Выберите уведомления</div>
                  <div class="notification__checkboxes">
                    <input
                      class="setting__common--check"
                      type="checkbox"
                      @input="
                        (val) => {
                          toggleEmailNotifications(val.target.value);
                        }
                      "
                      :checked="currentUser.profile.accept_email"
                      id="email-accept"
                      name="email-accept"
                    />
                    <label class="setting__common--label" for="email-accept"
                      >E-mail уведомления</label
                    >
                  </div>
                  <b-button
                    variant="link"
                    @click="toggleEmailNotifications(!currentUser.profile.accept_email)"
                    class="notification__text--blue"
                    >{{ currentUser.profile.accept_email ? 'Отключить' : 'Включить' }} все
                    уведомления на почту</b-button
                  >
                </div>
              </main>
            </template>
          </SettingsBlock>
        </div>
        <div id="settings__nav">
          <VueStickySidebar
            class="sidebar"
            containerSelector="#settings__nav"
            innerWrapperSelector=".sidebar__inner"
            :topSpacing="85"
          >
            <nav class="navigation">
              <h3 class="navigation__title">Навигация</h3>
              <ul class="navigation__link">
                <template v-if="currentUser.blogger_profile">
                  <li v-hover-to="'statSett'" class="navigation__item">
                    <a class="navigation__link">Статистика аккаунта</a>
                  </li>
                  <li v-hover-to="'themeSett'" class="navigation__item">
                    <a class="navigation__link">Темы аккаунта и тезисы</a>
                  </li>
                  <li v-hover-to="'kindSett'" class="navigation__item">
                    <a class="navigation__link">Форматы рекламы</a>
                  </li>
                </template>
                <li v-hover-to="'defSett'" class="navigation__item">
                  <a class="navigation__link">Общие настройки</a>
                </li>
                <li v-hover-to="'notifSett'" class="navigation__item">
                  <a class="navigation__link">Уведомления</a>
                </li>
              </ul>
            </nav>
          </VueStickySidebar>
        </div>
      </div>
    </div>
    <FillExample
      v-if="showFillExample"
      :openOn="fillExampleOpenOn"
      @close="showFillExample = false"
      :open="showFillExample"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { required, minLength, minValue, maxValue, between, email } from 'vuelidate/lib/validators';
import PhoneMaskInput from 'vue-phone-mask-input';
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
import VueStickySidebar from 'vue-sticky-sidebar';
import Multiselect from 'vue-multiselect';
import auth from '@main/api/users';
import FillExample from '@main/components/reusable/modals/FillExample.vue';
import bloggers from '@main/api/bloggers';
import StatisticFormValidationMixin from '@main/mixins/statistic/StatisticFormValidationMixin.vue';
import StatisticFileInput from '@main/components/settings/StatisticFileInput.vue';
import enums from '@main/enums';
import crypto from '@main/func/crypto';
import position from '@main/func/position';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import SettingsBlock from './SettingsBlock.vue';
import PrTypeForm from '../reusable/statistics/PrTypeForm.vue';
import LegalStatusRadioGroup from '../reusable/elements/radio/LegalStatusRadioGroup.vue';
import NestedListFormContainer from '../reusable/functional/NestedListFormContainer.vue';
import CoverageField from '../statistics_form/fields/CoverageField.vue';
import CoveragePostsField from '../statistics_form/fields/CoveragePostsField.vue';
import WomenPercentField from '../statistics_form/fields/WomenPercentField.vue';
import MainCountryField from '../statistics_form/fields/MainCountryField.vue';
import MainAudienceField from '../statistics_form/fields/MainAudienceField.vue';
import MainCityField from '../statistics_form/fields/MainCityField.vue';

export default {
  components: {
    SettingsBlock,
    VueStickySidebar,
    Multiselect,
    PhoneMaskInput,
    FillExample,
    LegalStatusRadioGroup,
    NestedListFormContainer,
    CoverageField,
    CoveragePostsField,
    WomenPercentField,
    MainCountryField,
    MainCityField,
    MainAudienceField,
  },
  mixins: [StatisticFormValidationMixin],
  data: () => ({
    error: '',
    email: '',
    bloggerLinkCopied: false,
    fileLoading: false,
    ageGroup: 0,
    model: null,
    currentItem: null,
    showPhoneModal: false,
    showEmailActivation: false,
    showFillExample: false,
    fillExampleOpenOn: null,
    moderationHistoryData: { groups: [] },
    PrTypeForm,
  }),
  watch: {
    // eslint-disable-next-line func-names
    'model.blogger_profile.legal_status': function (val) {
      if (val === 1) {
        this.model.blogger_profile.ogrnip = null;
      }
    },
    'model.blogger_profile.pr_types': (val) => {
      console.log(val);
    },
  },
  computed: {
    ...mapState(['bloggerOptions', 'currentUser', 'countries', 'tags']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    prTypeModel() {
      const model = this.lodash.cloneDeep(this.bloggerOptions.actions.POST.pr_types.child.children);
      const keys = Object.keys(model);
      for (let i = 0; i < keys.length; i += 1) {
        model[keys[i]] = null;
      }
      return model;
    },
    bloggerLink() {
      return `${window.location.origin}/#/bloggers/${this.currentUser.blogger_profile.instaname}/?access=${this.currentUser.blogger_profile.da_token}`;
    },
    commonBloggerDataChanged() {
      const keyList = ['theses', 'tags', 'excluded_tags'];
      return !keyList.every((el) => {
        return this.lodash.isEqual(
          this.model.blogger_profile[el],
          this.currentUser.blogger_profile[el]
        );
      });
    },
    commonDataChanged() {
      if (!this.currentUser.blogger_profile) {
        return;
      }
      const keyList = ['ambassador', 'current_city', 'legal_status'];
      return !keyList.every((el) => {
        return this.lodash.isEqual(
          this.model.blogger_profile[el],
          this.currentUser.blogger_profile[el]
        );
      });
    },
    bloggerProfileChanged() {
      const keyList = [
        'coverage_min',
        'coverage_max',
        'coverage_posts_min',
        'coverage_posts_max',
        'main_city',
        'main_country',
        'main_audience',
        'coverage_screens',
        'posts_coverage_screens',
        'women_percent_screens',
        'females_percent',
      ];
      return !keyList.every((el) => {
        const still = this.lodash.isEqual(
          this.model.blogger_profile[el],
          this.currentUser.blogger_profile[el]
        );
        if (!still) {
          console.log(this.model.blogger_profile[el], this.currentUser.blogger_profile[el]);
        }
        return still;
      });
    },
    badModerationSteps() {
      if (!this.moderationHistoryData) {
        return [];
      }
      return this.moderationHistoryData.groups
        .filter((val) => val.reasons.length)
        .map((val) => val.moderation_step);
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('popups', [
      'toggleChangePasswordModal',
      'toggleInstagramVerificationModal',
      'toggleChangePhoneModal',
    ]),
    validateEmail(mail) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(mail).toLowerCase());
    },
    showFill(scrollOn) {
      this.fillExampleOpenOn = scrollOn;
      this.showFillExample = true;
    },
    async changeEmail() {
      try {
        const response = await auth.sendEmail(this.model.profile.email);
        if (response.success) {
          this.$store.commit('notifications/showMessage', {
            title: 'E-mail успешно изменен, на указанную почту выслано письмо с подтверждением!',
            icon: 1,
            id: 837483,
          });
        }
        this.loadUser();
      } catch (e) {
        this.$store.commit('notifications/showMessage', {
          title:
            'Ошибка при изменении E-mail! Попробуйте позже или обратитесь в техническую поддержку',
          iconPath: alertGreyIcon,
        });
      }
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        try {
          let modersent;
          if (this.bloggerProfileChanged) {
            await bloggers.sendToModeration();
            modersent = true;
          }
          await auth.updateMe(this.model);
          this.$store.commit('notifications/showMessage', {
            title: `Настройки изменены! ${modersent ? 'Карточка отправлена на модерацию' : ''}`,
            icon: 1,
          });
          await this.loadUser();
          this.$set(this, 'model', this.lodash.cloneDeep(this.currentUser));
        } catch (e) {
          if (e.response.status >= 500) {
            this.$store.dispatch('notifications/setWindow', {
              iconPath: alertGreyIcon,
              text: 'Возникла ошибка при изменении настроек, попробуйте позднее или обратитесь в техническую поддержку - help@easyprbot.com!',
            });
          }
        }
      } else {
        this.$store.commit('notifications/showMessage', {
          title: 'Введите корректные данные!',
          icon: 2,
        });
      }
    },
    async toggleEmailNotifications(val) {
      await auth.updateMe({
        id: 36260,
        profile: { ...this.lodash.cloneDeep(this.currentUser.profile), accept_email: val },
      });
      await this.loadUser();
    },
  },
  async mounted() {
    console.log(this.$refs);
    try {
      this.$set(this, 'model', this.lodash.cloneDeep(this.currentUser));
    } catch (e) {
      console.log(e);
    } finally {
      if (
        this.currentUser &&
        this.currentUser.blogger_profile &&
        enums.bloggers.moderation_statuses.groups.unsafe
          .concat(6)
          .includes(this.currentUser.blogger_profile.moderation_status)
      ) {
        const moderationHistoryData = await bloggers.moderation.moderation_history_items.me();
        this.moderationHistoryData = moderationHistoryData;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.settings__info--block.sett__inp--percent::after {
  content: '%';
  position: absolute;
  right: 11px;
  top: 9px;
}
.navigation {
  width: 100% !important;
}
#settings__nav {
  width: 23%;
  height: 3062px;
  display: block;
}
@media (max-width: 1024px) {
  #settings__nav {
    display: none !important;
  }
}
.btn__uploaded {
  width: 120px;
  height: 36px;
}
.format_pr-btn-sett {
  margin-top: 30px;
}
::v-deep .kinds-sett-content {
  padding: 17px !important;
}
.settings__input {
  text-align: left;
}
.pay-btn {
  width: 145px;
  height: 37px;
}
.input--wd {
  text-align: left !important;
}
@media (max-width: 422px) {
  .mobile__flx-wrap {
    flex-wrap: wrap;
  }
  .settings__themes--wrap {
    margin-right: 0px !important;
  }
  .settings__themes--textarea {
    width: 100% !important;
  }
  .settings__container {
    padding: 10px !important;
  }
  .section.section__settings.sect_sett__closed {
    display: none !important;
  }
  .payment__radios {
    flex-direction: column;
  }
  .payment-radio__wrap {
    width: 100% !important;
    height: 60px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }
  .payment__radio {
    margin-right: 0px !important;
  }
  .payment-radio__text {
    flex-direction: row !important;
  }
  .label.settings__radio--button {
    margin-bottom: 0px !important;
  }
}

::v-deep .stata__textarea {
  width: 97% !important;
}
::v-deep .stata__radio {
  width: 100% !important;
}

.pr-types-sett__footer {
  padding-left: 0px;
  padding-bottom: 0px;
}

.set-fields {
  max-width: 350px !important;
}

@media (max-width: 760px) {
  .set-fields {
    max-width: 400px !important;
  }
}

@media (min-width: 999px) {
  .container {
    max-width: 1350px !important;
  }
}
::v-deep .fmt-rd {
  min-width: 50px !important;
}
form {
  position: relative !important;
}
</style>
