<template>
  <div v-bind="$attrs" class="sett_block">
    <h3 class="settings__title settings__title--mobile" style="padding: 0 0px 15px 0">
      <div>
        <div class="settings__stata">{{ header }}</div>
        <div class="settings__title--opac">{{ subheader }}</div>
      </div>
      <div class="settings__title--mobile--wrap">
        <div @click="toggleShow" class="settings__title--opac" style="color: #3897f0">
          {{ show ? 'Свернуть' : 'Развернуть' }}
        </div>
        <div class="settings--icon--wrap">
          <svg class="settings--icon--mobile">
            <use v-if="show" xlink:href="@main/assets/img/icons/sprite.svg#dropdown-up"></use>
            <use v-else xlink:href="@main/assets/img/icons/sprite.svg#dropdown"></use>
          </svg>
        </div>
      </div>
    </h3>
    <section
      v-if="mobileShow"
      :style="{
        height: show ? '' : '60px',
      }"
      :class="{ sect_sett__closed: !show }"
      class="section section__settings"
    >
      <h3 class="settings__title" style="padding: 15px 0px 0 20px">
        <span>{{ header }}</span>
        <span class="settings__title--opac">{{ subheader }}</span>
        <span @click="toggleShow" class="settings__title--opac">
          {{ show ? 'Свернуть' : 'Развернуть' }}</span
        >
      </h3>
      <b-collapse :class="contentClass" v-model="show" name="fade"
        ><slot name="content" v-bind="{ refs: $refs }"></slot>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
  props: {
    header: String,
    subheader: String,
    contentClass: String,
    foldable: { type: Boolean, default: true },
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
  computed: {
    mobileShow() {
      if (!this.isMobile) {
        return true;
      }
      return this.show;
    },
  },
  data: () => ({
    show: true,
  }),
};
</script>

<style lang="scss" scoped>
.section__settings.sect_sett__closed::after {
  display: none;
}
.sett_block {
  margin-bottom: 30px;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: ease opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
